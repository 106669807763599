@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// @primary-color: #1890ff; // primary color for all components
// @link-color: #1890ff; // link color
// @success-color: #52c41a; // success state color
// @warning-color: #faad14; // warning state color
// @error-color: #f5222d; // error state color
// @font-size-base: 14px; // major text font size
// @heading-color: rgba(0, 0, 0, 0.85); // heading text color
// @text-color: rgba(0, 0, 0, 0.65); // major text color
// @text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
// @disabled-color: rgba(0, 0, 0, 0.25); // disable state color
// @border-radius-base: 2px; // major border radius
// @border-color-base: #d9d9d9; // major border color
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//   0 9px 28px 8px rgba(0, 0, 0, 0.05);
// @font-family: 'Rubik', sans-serif;
//Whitelabel Fallback
:root {
  --primary-color: #735F32;
  --secondary-color: #C69749;
  --tersier-color: #282A3A;
}

html,
body,
#root,
#root > * {
  height: 100%;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

a {
  text-decoration: underline;
  cursor: pointer;
}
