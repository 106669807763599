.ant-list-item {
  padding: 12px 12px !important;
}

.ant-table-cell .ant-btn.ant-btn-link {
  padding: 0 4px !important;
}

.ant-btn + .ant-btn {
  margin-left: 10px;
}

.ant-btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);

}

.ant-tooltip-inner > span > span {
  padding: 5px;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: var(--primary-color);

}
